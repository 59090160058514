(function () {
    // Create the noscript element
    var script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "cc4720ef-bdf7-47e4-b840-a2804cef4885");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";
    if (import.meta.env.VITE_REGION == "EU") {
        document.head.appendChild(script);
        // eslint-disable-next-line no-inner-declarations
        function CookieAccepted() {
            console.log("grant");
            window.dataLayer.push({
                "event": "adroll_pixel",
                "CookieConsent": "accepted",
            });
        }
        document.addEventListener("click", function (event) {
            if (event.target && event.target.id === "CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll") {
                CookieAccepted();
            } else if (
                event.target &&
                event.target.id === "CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection"
            ) {
                CookieAccepted();
            } else if (typeof window.adroll === "undefined" && typeof window.Cookiebot !== "undefined") {
                var consent = window.Cookiebot.consent;
                if (consent.marketing && typeof window.adroll === "undefined") {
                    CookieAccepted();
                }
            }
        });
        window.addEventListener("load", function () {
            console.log("Page fully loaded");
            if (typeof window.Cookiebot !== "undefined") {
                // Ensure Cookiebot is fully loaded and initialized
                var consent = window.Cookiebot.consent;
                var adroll = typeof window.adroll === "undefined";
                console.log(consent, adroll, window.adroll);
                if (consent.marketing && typeof window.adroll === "undefined") {
                    CookieAccepted();
                }
            }
        });
    }
})();
